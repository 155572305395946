import React from 'react'
import { graphql } from 'gatsby'
import Article, {
  VARIANT_TEXT_INTENDED,
  VARIANT_TEXT_ALIGN_CENTERED_NARROW,
} from '../modules/Article.js'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'

const pageConfig = {
  title: `Kontakt - ${config.siteTitle}`,
  slug: `kontakt`,
  path: `kontakt`,
}

const Page = ({ data }) => {
  const postNode = {
    title: pageConfig.title,
  }

  const block1 = {
    as: 'header',
    leading: 5,
    variant: VARIANT_TEXT_ALIGN_CENTERED_NARROW,
    headline: `Kontakt`,
    lead: `<p>Vereinbaren Sie Ihren persönlichen Beratungstermin mit uns. Kostenlos und unverbindlich. Schreiben Sie uns an:</p>`,
    actions: [
      {
        variant: 'secondary',
        href: 'mailto:info@raumausstattung-ziegler.de',
        label: 'info@raumausstattung-ziegler.de',
        isUppercase: false,
      },
    ],
  }

  const block2 = {
    as: 'div',
    leading: 5,
    variant: VARIANT_TEXT_INTENDED,
    copytext: `<p>Bitte beachten Sie: Viele unserer Arbeiten und Beratungen finden außer Haus statt. Deswegen kann es bei Termin-Engpässen zu kurzfristigen Änderungen der Öffnungszeiten kommen.</p>`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{pageConfig.title}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath={pageConfig.path} customTitle />

      <div>
        <article>
          <Article {...block1}></Article>
          <Article {...block2}></Article>
        </article>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    opener: file(relativePath: { eq: "polstern-opener.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
